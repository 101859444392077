import CrmCorpContragentTablePage from "./CrmCorpContragentTablePage.vue"
import CrmCorpContragentEditPageDynamic from "./CrmCorpContragentEditPageDynamic.vue"
import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue"

export default {
  path: "/crm_corp",
  component: DashboardLayout,
  children: [{
    path: "contragent",
    name: "CrmCorpContragentTablePage",
    component: CrmCorpContragentTablePage
  },
  {
    path: "contragent/:CounterpartyId/show",
    name: "CrmCorpContragentEditPageDynamicShow",
    component: CrmCorpContragentEditPageDynamic,
  },
    // {
    //   path: "contragent/:CounterpartyId/edit",
    //   name: "CrmCorpContragentEditPageDynamicEdit",
    //   component: CrmCorpContragentEditPageDynamic,
    // },
    // {
    //   path: "contragent/create",
    //   name: "CrmCorpContragentEditPageDynamicCreate",
    //   component: CrmCorpContragentEditPageDynamic,
    // },
  ]
}
