import { API_METHODS } from "../../constant";
export const companyApi = (
  _getAll,
  _get,
  _update,
  _create,
  _delete,
  paramsIn,
  HttpClientLocal
) => {
  return {
    [API_METHODS.GET_COMPANY]: () => {
      let urlReq =
        `/Company/${paramsIn.typeCompany ? paramsIn.typeCompany : "Client"}` +
        "/" +
        paramsIn.id;
      return _get(urlReq);
    },
    [API_METHODS.GET_ALL_COMPANY]: () => {
      let urlReq = `/Company/${
        paramsIn.typeCompany ? paramsIn.typeCompany : "Client"
      }`;
      return _getAll(urlReq);
    },
    [API_METHODS.UPDATE_COMPANY]: () => {
      let urlReq = `/Company`;
      return _update(urlReq);
    },
    [API_METHODS.CREATE_COMPANY]: () => {
      let urlReq = `/Company`;
      return _create(urlReq);
    },
    [API_METHODS.DELETE_COMPANY]: () => {
      let urlReq = `/Company/${paramsIn.id}`;
      return _delete(urlReq);
    },


   /**
     * отсоединяем код и компанию
     */
  [API_METHODS.COMPANY_UNCONNECT_CODE]: () => {
    let urlReq = "/Company/CodeCompany/Delete";
    const sendData = {
      companyId: paramsIn.companyId,
      codeId: paramsIn.codeId
    };
    return HttpClientLocal.putRequest(urlReq, sendData);

  }





  };
};
