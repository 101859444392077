<template>
  <card :title="$t('Разрешённые договоры')">
    <load-block :load="load"></load-block>
    <div class="card-body">
      <div class="row">
        <div class="col-4">
          <BaseInput
            v-if="contractConfig"
            :config="contractConfig"
            :form="loadData2"
            :readonly="false"
          />
        </div>
        <div class="col-4">
          <label>&nbsp;</label>
          <l-button
            type="success"
            wide
            block
            @click="createData"
          >{{ $t("Добавить разрешенный договор") }}
          </l-button>
        </div>
        <div class="col-12">
          <BaseInput
            v-if="contractData"
            :config="contractData"
            :form="loadData2"
            :readonly="true"
          />


        </div>
      </div>

    </div>
  </card>
</template>

<script>
import {API_METHODS, TYPE_ERRORS} from "../../../constant";
import LoadBlock from "../../shared/LoadBlock";
import notify from "../../../helpers/notify/notify";
import BaseInput from "../generator/BaseComponets/BaseInput.vue";
import {ApiService} from "../../../util/ApiService";

export default {
  name: "ContractorAccess",
  components: {
    BaseInput,
    LoadBlock,
  },
  data() {
    return {
      showOrg: true,
      loadData2: {
        contract: '',
        contractData: '',
      },
      show: false,
      option: [],
      load: false,
      contractConfig: null,
      tableData: [],
      isModeEdit: false,
    };
  },
  computed: {},
  created() {
    this.contractConfig =
      this.getCustomConfigForInput('contract', 'contract', 'договор', 12);
    this.contractData =
      this.getCustomConfigForInput('contractData', 'contractData', 'Разрешённые договоры', 12);
    this.getData();
  },
  methods: {
    getCustomConfigForInput(name = '', fieldName = '', label = '', col = 3, readonly = false, enable=true) {
      return {
        type: "INPUT",
        description: "Текстовое поле",
        main: false,
        default: null,
        colsXS: col,
        colsSM: col,
        colsMD: col,
        colsLG: col,
        OffsetXS: 0,
        OffsetSM: 0,
        OffsetMD: 0,
        OffsetLG: 0,
        addClass: "",
        name: name,
        fieldName: fieldName,
        label: label,
        placeholder: null,
        validationRule: null,
        errName: null,
        cyData: null,
        enable: true,
        readonly: false,
        showTable: true,
        disableInCreate: false,
        relative: null,
        SearchType: "String",
        subListId: 0,
        isReadOnlyAdd: false,
        isReadOnlyEdit: false,
        watchAttr: null,
      };
    },

    async createData() {
      const {contract} = this.loadData2;
      try {
        const params = {
          self: this,
          saveData: {
             value : Number(contract),
          },
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        await ApiService(API_METHODS.ACCESS_ORG, params);
        this.load = false;
        await this.getData()
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },
    async getData() {
      const {contract} = this.loadData2;
      try {
        const params = {
          self: this,
          query: {
            lang: this.$store.state.common.lang
          }
        };
        this.load = true;
        const res = await ApiService(API_METHODS.GET_ACCESS_ORG, params);
        this.loadData2.contractData = res
        this.load = false;
      } catch (err) {
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
        this.load = false;
      }
    },


    showNotification(res) {
      if (res.isValid)
        notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
      else
        notify(this, this.$t("Ошибка"), TYPE_ERRORS.DANGER);
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  margin: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
