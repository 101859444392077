import HttpClient from "./HttpClient";
import { entitiesApi } from "./entitiesApi/entities";
import { attrEntityApi } from "./entitiesApi/arrtEnity";
import { personApi } from "./entitiesApi/personApi";
import { personDocApi } from "./entitiesApi/personDocApi";
import { personBonusCardApi } from "./entitiesApi/personBonusCardApi";
import { personContactApi } from "./entitiesApi/personContactApi";
import { personVisaApi } from "./entitiesApi/personVisaApi";
import { personPrefDataApi } from "./entitiesApi/personPrefDataApi";
import { personCodeValueApi } from "./entitiesApi/personCodeValueApi";
import { travelApi } from "./entitiesApi/travelApi";
import { companyApi } from "./entitiesApi/companyApi";
import { userApi } from "./entitiesApi/userApi";
import { contractApi } from "./entitiesApi/contractApi";
import { supplierServicesApi } from "./entitiesApi/supplierServicesApi";
import { contactApi } from "./entitiesApi/contactApi";
import { addressApi } from "./entitiesApi/addressApi";
import { corpPolicyApi } from "./entitiesApi/corpPolicyApi";
import { commertialPolicyApi } from "./entitiesApi/commertialPolicyApi";
import { attrValueApi } from "./entitiesApi/attrValueApi";
import { codeApi } from "./entitiesApi/codeApi";
import { contractCodeApi } from "./entitiesApi/contractCodeApi"

import { agreementsApi } from "./entitiesApi/agreementsApi";
import { counterpartyApi } from "./entitiesApi/counterpartyApi";
import { counterpartyAgreementsApi } from "./entitiesApi/counterpartyAgreementsApi";
import { counterpartyBankDetailsApi } from "./entitiesApi/counterpartyBankDetailsApi";
import { sheduleApi } from "./entitiesApi/sheduleApi";
import { multiSelectApi } from "./entitiesApi/multiSelect";

import { cfoApi } from "./entitiesApi/cfoApi";
import { receiverSystemsApi } from "./entitiesApi/receiverSystemsApi";
import { historyApi } from "./entitiesApi/historyApi";
import { crmCorpApi } from "./entitiesApi/crmCorpApi";
import { hrFeedApi } from "./entitiesApi/hrFeedApi";
import { hrFeedFields } from './entitiesApi/hrFeedFields'
import { statisticApi } from "@/util/entitiesApi/statisticApi";
import {syncCorteosApi} from "./entitiesApi/syncCorteosApi";
 /**
 * ApiService
 * @param method
 * @param paramsIn
 * @param rootState
 * @returns {*}
 */

export const ApiService = (method, paramsIn) => {
  let HttpClientLocal = new HttpClient(paramsIn.self);
  let urlReq = "";
  /**
   * метод получение всех записей
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _getAll = (urlReq, dopParams = {}) => {
    let params = {
      page: paramsIn.page,
      size: paramsIn.size,
      lang: paramsIn.lang,
      search: paramsIn.search ? paramsIn.search : null,
      lastUpdate: paramsIn.lastUpdate ? paramsIn.lastUpdate : null,
      orderBy: paramsIn.orderBy,
      orderDir: paramsIn.orderDir == "ascending",
      ...dopParams
    };
    return HttpClientLocal.getRequest(urlReq, params);
  };

  /**
   * метод получения конкретной записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _get = (urlReq, paramsDop = null) => {
    let paramsSend = {
      lang: paramsIn.lang
    };
    if (paramsDop) {
      paramsSend = { ...paramsSend, ...paramsDop };
    }
    return HttpClientLocal.getRequest(urlReq, paramsSend);
  };

   /**
    * @param urlReq
    * @returns {*}
    * @private
    */
   const _getNoError = (urlReq, paramsDop = null) => {
     let paramsSend = {
       lang: paramsIn.lang
     };
     if (paramsDop) {
       paramsSend = { ...paramsSend, ...paramsDop };
     }
     return HttpClientLocal.getRequest(urlReq, paramsSend, false);
   };



  /**
   * обновление записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _update = urlReq => {
    let urlSend = urlReq;
    try {
      if (paramsIn.lang) {
        urlSend += "?lang=" + paramsIn.lang;
      }
    } catch (e) {
      console.error(e);
      urlSend = urlReq;
    }
    return HttpClientLocal.putRequest(urlSend, paramsIn.saveData);
  };
  /**
   * создании записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _create = urlReq => {
    let urlSend = urlReq;
    try {
      if (paramsIn.lang) {
        urlSend += "?lang=" + paramsIn.lang;
      }
    } catch (e) {
      console.error(e);
      urlSend = urlReq;
    }
    return HttpClientLocal.postRequest(urlSend, paramsIn.saveData);
  };

   /**
    * @param urlReq
    * @returns {*}
    * @private
    */
   const _createNoError = urlReq => {
     let urlSend = urlReq;
     try {
       if (paramsIn.lang) {
         urlSend += "?lang=" + paramsIn.lang;
       }
     } catch (e) {
       console.error(e);
       urlSend = urlReq;
     }
     return HttpClientLocal.postRequest(urlSend, paramsIn.saveData, false);
   };


  /**
   * удаление записи
   * @param urlReq
   * @returns {*}
   * @private
   */
  const _delete = urlReq => {
    return HttpClientLocal.putRequest(urlReq);
  };

   const _deleteReal = urlReq => {
     return HttpClientLocal.deleteRequest(urlReq);
   };
    /**
   * метод для востановления данных
   * @param urlReq
   * @returns {*}
   * @private
   */
     const _recoverData = urlReq => {
      let sendDate = {
        id: paramsIn.id,
        dateCreated: paramsIn.dateCreated
      };
      return HttpClientLocal.putRequest(urlReq, sendDate);
    };
  /**
   * метод получения данных для поиска
   * @param urlReq
   * @param query
   * @returns {*}
   * @private
   */
   const _loadData = (urlReq, query) => {
    return HttpClientLocal.getRequest(urlReq, query);
  };

  const _loadFile = (urlReq) => {
    const formData = new FormData();
    formData.append('uploadedFile', paramsIn.uploadedFile)
    return HttpClientLocal.postRequestFile(urlReq, formData);
  }

  const _getFileStatistic = (urlReq, dopParams = {}) => {
    let params = {
      page: paramsIn.page,
      size: paramsIn.size,
      lang: paramsIn.lang,
      search: paramsIn.search ? paramsIn.search : null,
      lastUpdate: paramsIn.lastUpdate ? paramsIn.lastUpdate : null,
      orderBy: paramsIn.orderBy,
      orderDir: paramsIn.orderDir == "ascending",
      ...dopParams
    };
    return HttpClientLocal.getFileRequest(urlReq, params);
  }

  const _getFile = (urlReq, paramsDop = null) => {
     let paramsSend = {
       lang: paramsIn.lang
     };
     if (paramsDop) {
       paramsSend = { ...paramsSend, ...paramsDop };
     }
     return HttpClientLocal.getFileRequestFull(urlReq, paramsSend);
   };

  const allApi = {
    ...entitiesApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...attrEntityApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...personApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...personDocApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...personBonusCardApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...personContactApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...personVisaApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...userApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...personPrefDataApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...personCodeValueApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...travelApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...companyApi(_getAll, _get, _update, _create, _delete, paramsIn, HttpClientLocal),
    ...contractApi(_getAll, _get, _update, _create, _delete, paramsIn, _recoverData),
    ...supplierServicesApi(  _getAll, _update, _create, _delete, paramsIn, _loadData, HttpClientLocal),
    ...contactApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...addressApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...corpPolicyApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...commertialPolicyApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...attrValueApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...codeApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...contractCodeApi(_getAll, _get, _update, _create, _delete, paramsIn),

    ...agreementsApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...counterpartyApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...counterpartyAgreementsApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...counterpartyBankDetailsApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...sheduleApi(_getAll, _get, _update, _create, _delete, paramsIn),

    ...multiSelectApi(_getAll, _get, _update, _create, _delete, paramsIn),

    ...cfoApi(_getAll, _get, _update, _create, _delete, paramsIn),

    ...receiverSystemsApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...historyApi(_getAll, _get, _update, _create, _delete, paramsIn),
    ...crmCorpApi(_getAll, _get, _update, _create, _delete, paramsIn),

    ...hrFeedApi(_getAll, _get, _update, _create, _delete, paramsIn, _loadFile),
    ...hrFeedFields(_getAll, _get, _update, _create, _delete, paramsIn, _loadFile),

    ...statisticApi(_getAll, _get, _update, _create, _delete, _getFileStatistic, paramsIn, _getFile),

    ...syncCorteosApi(_getAll, _get, _create, _update, _deleteReal, _getNoError, _createNoError, paramsIn),
  }

  if (method in allApi) {
    const findApi = allApi[method]
    return findApi()
  } else {
    console.error(`ошибка: api ${method} - не реализовано!`);
    return false;
  }

};
