<template>
  <div :class="generateClass">
    <fg-input
      :label="config.label"
      :data-cy="config.cyData"
      :placeholder="config.placeholder"
      v-model="form[config.fieldName]"
      :readonly="readonly"

      :name="nameF"
      v-validate.initial="rule"
      :error="getError(nameF)"
      :data-vv-as="config.label"
      :errorsMsg=getErrorMas(nameF)
      :capitalize="config.capitalize"
    >
    </fg-input>
  </div>
</template>
<script>
  import {generateClass} from '../../../../mixins/baseComponets/generateClass'
  import {validateExt} from '../../../../mixins/baseComponets/validateExt'

  export default {
    name: 'BaseInput',
    props: {
      config: {type: Object, required: true},
      form: {type: Object},
      languageCode: {type: String} // призанак того что стоит выбрать правила проверки относительно языка
    },
    data(){
      return {
         rule: '',
         nameF: ''
      }
    },
    created() {
      const { languageCode , config} = this
      this.nameF = languageCode? config.fieldName+languageCode:config.fieldName
      this.rule = languageCode && config.validateRu && config.validateEn?languageCode=='ru'?config.validateRu:config.validateEn:config.validationRule
    },


    mixins: [generateClass, validateExt]
  }
</script>
