import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
import ContractorAccess from "./ContractorAccess.vue";
const Access = () =>
    import ('./PageAccess.vue')
const ImportHelperPage = () => import('./ImportHelperPage.vue')
export default {
    path: '/setting',
    component: DashboardLayout,
    children: [{
        path: 'access',
        name: 'Access',
        component: Access
    },
      {
        path: 'importCortes',
        name: 'Access',
        component: ImportHelperPage
      },
      {
        path: 'contractorAccess',
        name: 'ContractorAccess',
        component: ContractorAccess
      },




    ]
}
